<script setup>
import {
  ChatBubbleLeftRightIcon,
  FunnelIcon,
  EnvelopeIcon,
  InboxArrowDownIcon,
  WrenchScrewdriverIcon,
  RocketLaunchIcon
} from '@heroicons/vue/24/outline'
import Subtitle from '@components/Subtitle.vue'

const features = [
  {
    icon: ChatBubbleLeftRightIcon,
    title: 'We provide 24/7 customer service',
    description: 'Our customer service team is available through email anytime, ready to help and answer any questions you may have.'
  },
  {
    icon: FunnelIcon,
    title: 'We expertly review your application for errors or omissions',
    description: 'We ensure you avoid delays, red tape, and frustration with the ATO.'
  },
  {
    icon: InboxArrowDownIcon,
    title: 'Transparent process with instant notifications',
    description: 'We will keep you updated on the progress of your ABN registration through instant updates in your inbox.'
  },
  {
    icon: WrenchScrewdriverIcon,
    title: 'Do you need to register a business name?',
    description: 'We can also register your new business name when we register your ABN. Same form, no extra paperwork, bundled payment.'
  },
  {
    icon: RocketLaunchIcon,
    title: 'Fast and easy process',
    description: 'Filling an ABN application has never been so easy! We have simplified the complex ABN application process for you into a single and smart online form.'
  },
]
</script>

<template>
  <div class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <Subtitle>
        Why register your ABN with us?
      </Subtitle>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl class="mx-auto grid grid-cols-1 gap-x-6 gap-y-10 text-base/7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
        <div v-for="feature in features" :key="feature.title" class="relative pl-16">
          <dt class="inline font-semibold text-gray-900">
            <component :is="feature.icon" class="absolute left-1 top-1 size-12 text-indigo-600" aria-hidden="true" />
            {{ feature.title }}
          </dt>
          {{ ' ' }}
          <dd class="inline">{{ feature.description }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>
