<script setup>
import { Link } from '@inertiajs/vue3'

const props = defineProps({
  href: String,
  text: String,
})
</script>

<template>
  <Link
    target='_blank'
    :href="href"
    class="inline-block cursor-pointer text-center text-lg text-2xl font-semibold text-white bg-indigo-600 rounded-md my-4 px-6 py-2 p-4" >
    {{ props.text }}
  </Link>
</template>
